<template>
    <div class="selectors">
        <div class="custom-input-prefix">
            <multiselect
                track-by="id"
                label="name"
                placeholder="Selecionar item..."
                v-model="item"
                :options="items"
                :showLabels="false"
                :internalSearch="false"
                :searchable="true"
                @search-change="debounceSearch"
                @select="onSelectItem"
            >
                <template slot="caret">
                    <div v-if="!loading" class="chevron">
                        <ChevronDown />
                    </div>
                    <div v-else class="loading-icon">
                        <b-spinner small type="grow" variant="primary"></b-spinner>
                    </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                    <span v-if="option.code" class="code">{{ option.code }} </span>

                    <span 
                        v-if="option.name.length < 45"
                        class="item-label" 
                    >
                        {{ option.name }}
                    </span>
                    <span 
                        v-else
                        class="item-label"
                        v-b-tooltip.hover :title="option.name"
                    >
                        {{ option.name.substring(0,45)}} ...
                    </span>
                </template>
                <template slot="option" slot-scope="{ option }">
                    <div>
                        <span v-if="option.code" class="code">Código: {{ option.code }} </span>
                        <span class="item-label">{{ option.name }}</span>
                    </div>
                    <ItemTagType :type="option.type" />
                    <p class="disabled-description" v-if="option.$isDisabled">
                        Este procedimento é realizado em outra sala, crie um novo agendamento para adicioná-lo.
                    </p>
                </template>
                <template slot="noOptions">
                    Nenhuma opção
                </template>
                <template slot="noResult">
                    Nenhum resultado
                </template>
            </multiselect>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import api from '@items/api'
import { getCurrentClinic } from '@/utils/localStorageManager';

export default {
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        ItemTagType: () => import('@items/components/ItemTagType'),
    },
    props: {
        value: {
            validator: v => true
        },
        clinicHealthPlanId: String,
        planId: String,
        allowedTypes: { 
            type: Array, 
            required: true 
        },
        availableProcedures: Array,
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            loading: false,
            showSelectPlan: false,
            personHealthPlan: null,
            selectedPlan: null,
            item: null,
            healthPlans: [],
            plans: [],
            items: [],
            debounceSearch: Function
        }
    },
    created() {
        this.debounceSearch = debounce(this.searchItems, 300);
    },
    mounted() {
        this.populateValue()
    },
    methods: {
        clearSelectors() {
            this.items = []
            this.personHealthPlan = null
            this.selectedPlan = null
            this.item = null
        },
        onSelectItem(item) {
            this.$emit('select', item)
            this.$emit('input', item)
        },
        searchItems(query) {
            this.loading = true;
            api.searchItems(this.clinic.id, query, this.allowedTypes, this.clinicHealthPlanId, this.planId)
                .then(({ data }) => {
                    if(data.message === 'noOtherExpenseTableFind'){
                        this.$swal.fire({
                            icon: 'warning',
                            html: `<div class="body-alert">Atenção, o convênio <span class="mt-1">${data.health_plan_name}</span>
                                 não possui uma tabela de outras despesas cadastrada. Para prosseguir, necessário cadastrar a tabela.
                            </div>`,
                            showCancelButton: true,
                            confirmButtonColor: '#305BF2',
                            confirmButtonText: 'Cadastrar',
                            cancelButtonText: 'Voltar',
                            cancelButtonHoverColor: '#ff0000',
                            showCloseButton: true,
                            reverseButtons: true,
                            customClass: {
                                popup: "custom-swal-batch-review",
                            },
                        }).then(async (result) => {
                            if(result.isConfirmed) this.$router.push('/convenio/' +data.health_plan_id+ '/tables-tab')
                        })
                    }   
                    else{
                        this.items = data ? data.map(item => ({
                            ...item,
                            // $isDisabled: !this.availableProcedures?.some(el => el.item_id === item.id)
                        })) : [];
                    }                 

                })
                .catch(err => {
                    this.$toast.error(err.message)
                })
                .finally(() => (this.loading = false));
        },
        populateValue() {
            if (!this.value) return
            this.items = [this.value]
            this.item = this.value
        }

    },
    watch: {
        value() {
            this.populateValue()
        }
    }
}
</script>

<style lang="scss" scoped>
.selectors {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}
.custom-input-prefix {
    width: 100%;
    display: inline-flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    height: 38px;
    border: 1px solid var(--neutral-300);
    /* padding: 15px 0 15px 16px; */
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 8px;
}
.loading-icon {
    display: flex;
    align-items: center;
    width: 24px;
    height:38px;
    padding: 0;
    right: 16px;
    position: absolute;
}
.item-label {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--dark-blue);
}
.code {
    font-size: 0.8rem;
    color: var(--blue-500);
}
.disabled-description {
  font-size: 12px;
  font-style: italic;
  color: var(--type-active);
}
</style>